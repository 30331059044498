<template>
  <GridItem :from="4" :to="11" class="column"><slot /></GridItem>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .column {
    // background-color: #0000ff;

    @include break-max {
      @include column(4, 9);
    }

    @include break-large {
      @include column(4, 9);
    }

    @include break-medium {
      @include column(4, 9);
    }

    @include break-small {
      @include column(4, 11);

      @include break-min-phone {
        margin-left: -$gutter-medium;
      }
    }

    @include break-phone {
      @include column(2, 11);
    }
  }
</style>
